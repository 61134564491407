import React, { useState, useEffect } from "react";
import "../register.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingIcons from "react-loading-icons";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { register } from "../redux/features/authSlice";
import { Button } from "react-bootstrap";

const initialState = {
  title: "None",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  work: false,
  newsletter: false,
  termsconditions: "",
};

const Register = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [isFormValid, setIsFormValid] = useState(false); // New state variable
  const { loading, error } = useSelector((state) => ({ ...state.auth }));
  const {
    title,
    email,
    password,
    firstName,
    middleName,
    lastName,
    confirmPassword,
    work,
    newsletter,
    termsconditions,
  } = formValue;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    // Check if all fields are filled and all three checkboxes are selected
    const isValid =
      title !== "None" &&
      email &&
      password &&
      firstName &&
      middleName &&
      lastName &&
      confirmPassword &&
      // work &&
      // newsletter&&
      termsconditions;

    setIsFormValid(isValid);
  }, [
    title,
    email,
    password,
    firstName,
    middleName,
    lastName,
    confirmPassword,
    termsconditions,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return toast.error("Password should match");
    }
    if (isFormValid) {
      dispatch(register({ formValue, navigate, toast }));
    } else {
      toast.error("Please fill in all fields and select all checkboxes");
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // const onInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   if (type === "checkbox") {
  //     setFormValue((prevData) => ({
  //       ...prevData,
  //       [name]: checked,
  //     }));
  //   } else {
  //     setFormValue((prevData) => ({
  //       ...prevData,
  //       [name]: name === 'email' ? value : capitalizeFirstLetter(value),
  //     }));
  //   }
  // };

  const onInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Set to true if checked, false if unchecked
      setFormValue((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormValue((prevData) => ({
        ...prevData,
        [name]:
          name === "email" || name === "password" || name === "confirmPassword"
            ? value
            : capitalizeFirstLetter(value),
      }));
    }
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Link
        to="https://fonts.googleapis.com/css?family=Roboto:400,700"
        rel="stylesheet"
      ></Link>
      <title>Bootstrap Simple Login Form with Blue Background</title>
      <Link
        to="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
        rel="stylesheet"
      />

      <section className="card">
        <div className="container py-5 h-30">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 card mb-4">
              <h2 className="card-header" style={{ color: "#F74B02" }}>
                <span className="pi pi-user-plus" style={{ color: "#067ADC" }}>
                  {" "}
                </span>{" "}
                {"    "}
                Sign Up{" "}
                <span
                  style={{
                    color: "#067ADC",
                    marginRight: "3px",
                    marginLeft: "3px",
                    fontWeight: "bold",
                  }}
                >
                  /
                </span>{" "}
                Register Now
              </h2>

              <p>
                <br />
                All fields marked as{" "}
                <i
                  className="bi bi-asterisk"
                  style={{ color: "red", fontSize: "10px" }}
                ></i>{" "}
                are mandatory
              </p>

              <div>
                <form onSubmit={handleSubmit} style={{ color: "#000000" }}>
                  <div className="form-group">
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      name="title"
                      value={title}
                      onChange={onInputChange}
                    >
                      <option value="None">Please Select Title</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                    </select>
                    <p>
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                      {title === "Select" ? (
                        <span>You selected {title}</span>
                      ) : (
                        `You selected ${title}`
                      )}
                    </p>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={firstName}
                      name="firstName"
                      onChange={onInputChange}
                      placeholder="First Name"
                      required="required"
                    ></input>

                    <label htmlFor="firstName">
                      First Name
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={middleName}
                      name="middleName"
                      onChange={onInputChange}
                      placeholder="Middle Name"
                      required="required"
                    />
                    <label htmlFor="middleName">
                      Middle Name
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      name="lastName"
                      onChange={onInputChange}
                      placeholder="Last Name"
                      required="required"
                    />
                    <label htmlFor="lastName">
                      Last Name
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      name="email"
                      onChange={onInputChange}
                      placeholder="Email"
                      required="required"
                    />
                    <label htmlFor="email">
                      Email
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      name="password"
                      onChange={onInputChange}
                      placeholder="Password"
                      required="required"
                    />
                    <label htmlFor="password">
                      Password
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      value={confirmPassword}
                      name="confirmPassword"
                      onChange={onInputChange}
                      placeholder="Confirm Password"
                      required="required"
                    />
                    <label htmlFor="confirmPassword">
                      Confirm Password
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>

                  <div className="form-group">
                    <label className="checkbox-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="termsconditions"
                        value={termsconditions}
                        name="termsconditions"
                        checked={termsconditions}
                        onChange={onInputChange}
                      />{" "}
                      I accept the <NavLink to="#">Terms of Use</NavLink> &amp;{" "}
                      <NavLink to="#">Privacy Policy</NavLink>
                      <i
                        className="bi bi-asterisk"
                        style={{ color: "red", fontSize: "10px" }}
                      ></i>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="checkbox-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="work"
                        value={work}
                        name="work"
                        checked={work}
                        onChange={onInputChange}
                      />{" "}
                      Please check this box if you are a consultant in your
                      field of work
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="checkbox-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        // required="required"
                        id="newsletter"
                        value={newsletter}
                        checked={newsletter}
                        name="newsletter"
                        onChange={onInputChange}
                      />{" "}
                      Please check this box if you would like to opt for our
                      newsletter?
                    </label>
                  </div>

                  <div className="form-group">
                    <br />
                    <Button
                      type="submit"
                      className={`btn btn-lg ${
                        isFormValid ? "btn-primary" : "btn-orange"
                      }`}
                      style={{ backgroundColor: isFormValid ? "" : "#F74B02" }}
                      disabled={!isFormValid || loading} // Disable the button if the form is not valid or during loading
                    >
                      {loading && <LoadingIcons.Bars />}
                      Sign Up
                    </Button>
                    <div className="hint-text">
                      Already have an account?{" "}
                      <NavLink to="/login">Login here</NavLink>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Register;
