import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { setLogout } from "../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import decode from "jwt-decode";
import Header from "./Header";

const Navbar = () => {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const token = user?.token;

  if (token) {
    const decodedToken = decode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      dispatch(setLogout());
    }
  }

  const handleLogout = () => {
    dispatch(setLogout());
    navigate("/");
  };

  return (
    <>
      <Header />
      <br />

      {/* <!-- ======= Top Bar ======= --> */}
      <div id="topbar" className="d-flex align-items-center fixed-top">
        <div className="container d-flex justify-content-between">
          <div className="contact-info d-flex align-items-center">
            {/* <i className="bi bi-envelope"></i>{" "}
            <NavLink to="mailto:contact@example.com">
              contact@example.com
            </NavLink> */}
            {/* <i className="bi bi-phone"></i>  */}
            ISSN 2046-1690
          </div>
          <div className="nav-item"></div>

          {user?.result?._id && (
            <>
              <NavLink to="/dashboard">
                <p className="header-text">
                  {" "}
                  <span
                    className="pi pi-circle-on"
                    style={{ color: "#00FF00", fontSize: "13px" }}
                  >
                    {" "}
                  </span>{" "}
                  Logged in & as by:{" "}
                  <span className="pi pi-user" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  {user?.result?.title}
                  {". "}
                  {user?.result?.firstName}
                  {"  "}
                  {user?.result?.middleName} {"  "}
                  {user?.result?.lastName}
                </p>
              </NavLink>
            </>
          )}

          {user?.result?._id ? (
            <>
              <NavLink to="/login">
                <p className="header-text" onClick={handleLogout}>
                  <span className="pi pi-sign-out" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  Logout
                </p>
              </NavLink>
              <NavLink to="/forgotpassword">
                <p className="header-text">
                  <span
                    className="pi pi-lock"
                    style={{ color: "#F74B02", fontSize: "13px" }}
                  >
                    {" "}
                  </span>{" "}
                  Forgot
                  <span
                    style={{
                      color: "#F74B02",
                      marginRight: "3px",
                      marginLeft: "3px",
                      fontWeight: "bold",
                    }}
                  >
                    /
                  </span>
                  Change Password
                </p>
              </NavLink>
            </>
          ) : (
            <NavLink to="/login">
              <p className="header-text">
                <span className="pi pi-sign-in" style={{ color: "#F74B02" }}>
                  {" "}
                </span>{" "}
                <span
                  style={{
                    color: "#1976CA",
                    marginRight: "10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textDecoration: "underline",
                  }}
                >
                  Login to access
                </span>
              </p>
            </NavLink>
          )}

          {user?.result?._id ? (
            <NavLink to="/dashboard">
              <p className="header-text">
                <span
                  className="pi pi-list"
                  style={{ color: "#F74B02", fontSize: "13px" }}
                >
                  {" "}
                </span>{" "}
                Dashboard
              </p>
            </NavLink>
          ) : (
            <NavLink to="/register">
              <p className="header-text">
                <span className="pi pi-user-plus" style={{ color: "#067ADC" }}>
                  {" "}
                </span>{" "}
                <span
                  style={{
                    color: "#F74B02",
                    marginRight: "10px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textDecoration: "underline",
                  }}
                >
                  Register Now
                </span>
              </p>
            </NavLink>
          )}

          <div className="d-none d-lg-flex social-links align-items-center">
            <NavLink
              to="http://twitter.com/WebmedCentral"
              target="_blank"
              className="twitter"
            >
              <i className="bi bi-twitter"></i>
            </NavLink>
            <NavLink
              to="https://www.facebook.com/WebmedCentral/"
              target="_blank"
              className="facebook"
            >
              <i className="bi bi-facebook"></i>
            </NavLink>
            {/* <NavLink to="#" className="instagram">
              <i className="bi bi-instagram"></i>
            </NavLink> */}
            {/* <NavLink to="#" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </NavLink> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
